import React from "react";
import { formatAddress, formatAmount } from "../../functions/common";
import { useNavigate } from "react-router-dom";
import CopyBtn from "../Coman/CopyBtn";

const CrossFixedTokens = ({ tokens, totalFixedFarming, loading }) => {
  const navigate = useNavigate();

  return (
    <div className="tab-pane fade show" id="crossfixed" role="tabpanel" aria-labelledby="nav-order-tab">
      <div className="table-responsive dataTablehistory row">
        {loading ? (
          <div className="d-flex justify-content-center my-5">
            <div class="spinner-border text-primary" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : (
          <>
            <h4>Total Farming</h4>
            <div className="col-xl-3 col-md-6 col-sm-6">
              <div className="card pull-up">
                <div className="card-body align-items-center flex-wrap">
                  <div className="d-flex align-items-center mb-4">
                    <a href="javascript:void(0)" className="ico-icon">
                      <img className="rounded-circle" style={{ height: "25px", width: "25px" }} src={tokens[0]?.icon} alt="" />
                    </a>
                    <div className="ms-4">
                      <a href="javascript:void(0)">
                        <h4 className="heading mb-0">{tokens[0]?.symbol}</h4>
                      </a>
                      <span>{tokens[0]?.name}</span>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between">
                    <p className="mb-0">Total Farm</p>
                    <p className="mb-0">
                      {formatAmount(totalFixedFarming)} {tokens[0]?.symbol}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <h4>Total Harvesting</h4>
            {tokens?.length
              ? tokens?.map((item, ind) => {
                  return (
                    <div className="col-xl-3 col-md-6 col-sm-6">
                      <div className="card pull-up">
                        <div className="card-body align-items-center flex-wrap">
                          <div className="d-flex align-items-center mb-4">
                            <a href="javascript:void(0)" className="ico-icon">
                              <img className="rounded-circle" style={{ height: "25px", width: "25px" }} src={item?.icon} alt="" />
                            </a>
                            <div className="ms-4">
                              <a href="javascript:void(0)">
                                <h4 className="heading mb-0">{item?.symbol}</h4>
                              </a>
                              <span>{item?.name}</span>
                            </div>
                          </div>
                          <div className="d-flex justify-content-between">
                            <p className="mb-0">Total Harvest</p>
                            <p className="mb-0">
                              {formatAmount(item?.totalHarvest)} {item?.symbol}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              : ""}
          </>
        )}
      </div>
    </div>
  );
};

export default CrossFixedTokens;
