import React from "react";

export default function SelectToken() {
  return (
    <div>
      {" "}
      <div className="modal fade" id="exampleModalCenter">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Select Token</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
              ></button>
            </div>
            <div className="modal-body px-0">
          <div className="px-3 pe-4">
          <div className="input-group search-area">
                <span className="input-group-text">
                  <a href="javascript:void(0)">
                    <svg
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M20.5605 18.4395L16.7527 14.6317C17.5395 13.446 18 12.0262 18 10.5C18 6.3645 14.6355 3 10.5 3C6.3645 3 3 6.3645 3 10.5C3 14.6355 6.3645 18 10.5 18C12.0262 18 13.446 17.5395 14.6317 16.7527L18.4395 20.5605C19.0245 21.1462 19.9755 21.1462 20.5605 20.5605C21.1462 19.9747 21.1462 19.0252 20.5605 18.4395V18.4395ZM5.25 10.5C5.25 7.605 7.605 5.25 10.5 5.25C13.395 5.25 15.75 7.605 15.75 10.5C15.75 13.395 13.395 15.75 10.5 15.75C7.605 15.75 5.25 13.395 5.25 10.5V10.5Z"
                        fill="var(--primary)"
                      />
                    </svg>
                  </a>
                </span>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search here..."
                />
              </div>
          </div>
              <hr />
              <h6 className="px-3">Default token</h6>
              <div style={{ height: "263px", overflowY: "scroll" }}>
                <div className="d-flex justify-content-between bg-token-ho align-items-center px-3 mt-2">
                  <div className="d-flex">
                    <img
                      src="./assets/images/icon-eth/ethereum.webp"
                      className="rounded-pill "
                      style={{ height: "26px ", width: "26px" }}
                      alt=""
                    />
                    <h6 className="ms-3 m-0">ETH</h6>
                  </div>
                  <div>
                    <h6 className="m-0">0</h6>
                    <p style={{ fontSize: "10px" }} className="m-0">
                      $0
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
