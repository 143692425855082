import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Tokens } from "../../config/config";
import FixedTokens from "./FixedTokens";
import FlexibleTokens from "./FlexibleTokens";
import CrossFixedTokens from "./CrossFixedTokens";
import CrossFlexibleTokens from "./CrossFlexibleTokens";
import { appContext } from "../../AppContext";
import { ethers } from "ethers";
import InfoModal from "../Coman/InfoModal";
import { dateDifference } from "../../functions/common";

export default function UserTable() {
  const {
    getContractFixed,
    getContractCrossFixed,
    getContractCrossFlexible,
    getContractFlexible,
    provider,
    address,
    isConnected,
    connectWallet,
  } = useContext(appContext);
  const pageLimit = 10;

  const [fixedFarms, setFixedFarms] = useState([]);
  const [flexibleFarms, setFlexibleFarms] = useState([]);

  const [crossFixedFarms, setCrossFixedFarms] = useState([]);
  const [crossFlexibleFarms, setCrossFlexibleFarms] = useState([]);

  const [fixedFarmsAll, setFixedFarmsAll] = useState([]);
  const [flexibleFarmsAll, setFlexibleFarmsAll] = useState([]);

  const [crossFixedFarmsAll, setCrossFixedFarmsAll] = useState([]);
  const [crossFlexibleFarmsAll, setCrossFlexibleFarmsAll] = useState([]);

  const [fixedLoading, setFixedLoading] = useState(true);
  const [flexibleLoading, setFlexibleLoading] = useState(true);

  const [crossFixedLoading, setCrossFixedLoading] = useState(true);
  const [crossFlexibleLoading, setCrossFlexibleLoading] = useState(true);

  const [currentPageFixed, setCurrentPageFixed] = useState(1);
  const [currentPageFlexible, setCurrentPageFlexible] = useState(1);

  const [currentPageCrossFixed, setCurrentPageCrossFixed] = useState(1);
  const [currentPageCrossFlexible, setCurrentPageCrossFlexible] = useState(1);

  const [pagesFixed, setPagesFixed] = useState(0);
  const [pagesFlexible, setPagesFlexible] = useState(0);

  const [pagesCrossFixed, setPagesCrossFixed] = useState(0);
  const [pagesCrossFlexible, setPagesCrossFlexible] = useState(0);

  const [infoModal, setInfoModal] = useState(false);
  const [modalInfo, setModalInfo] = useState(null);

  const getAllFarmFixed = async () => {
    try {
      setFixedLoading(true);
      const fixed = getContractFixed();
      const farms = await fixed.getHarvestOfAddress(address);
      const filter = farms.map((item) => {
        return {
          datetime: item?.datetime,
          id: item?.id,
          lockPer: item?.lockPer,
          token: item?.token,
          user: item?.user,
          value: item?.value,
        };
      });
      const reverse = await filter.reverse();
      setFixedFarmsAll([...reverse]);
      setPagesFixed(Math.ceil(farms?.length / pageLimit));
    } catch (err) {
      setFixedLoading(false);
      // console.log("error : ", err);
    }
  };

  const paginateFixed = () => {
    try {
      setFixedLoading(true);
      let allRec = [...fixedFarmsAll];
      let start = pageLimit * currentPageFixed - pageLimit;
      const filterRec = allRec.splice(start, pageLimit);
      let arr = [];
      for (let index = 0; index < filterRec.length; index++) {
        const element = filterRec[index];

        const token = Tokens.find((item) => item.address?.toLowerCase() === element.token?.toLowerCase());
        // const harvestToken = Tokens.find((item) => item.address?.toLowerCase() === element.harvestToken?.toLowerCase());
        // const harvestToken = Tokens.find((item) => item.address?.toLowerCase() === element.token?.toLowerCase());
        let obj = {
          ind: start + index + 1,
          id: element.id,
          datetime: element.datetime,
          lockPer: ethers.utils.formatEther(element.lockPer),
          token: element.token,
          value: ethers.utils.formatEther(element.value),
          token: { ...token },
          // harvestToken,
        };
        arr.push(obj);
      }

      setFixedFarms([...arr]);
      setFixedLoading(false);
    } catch (err) {
      setFixedLoading(false);
      // console.log("error in paginateFixed : ", err);
    }
  };

  useEffect(() => {
    paginateFixed();
  }, [fixedFarmsAll, currentPageFixed]);

  useEffect(() => {
    if (Tokens) {
      setFixedLoading(true);
      getAllFarmFixed();
    }
  }, [Tokens, provider, address]);

  const getAllFarmFlexible = async () => {
    try {
      setFlexibleLoading(true);
      const flexible = getContractFlexible();
      const farms = await flexible.getHarvestOfAddress(address);
      const filter = farms.map((item) => {
        return {
          datetime: item?.datetime,
          id: item?.id,
          lockPer: item?.lockPer,
          token: item?.token,
          user: item?.user,
          value: item?.value,
        };
      });
      const reverse = await filter.reverse();
      setFlexibleFarmsAll([...reverse]);
      setPagesFlexible(Math.ceil(farms?.length / pageLimit));
    } catch (err) {
      setFlexibleLoading(false);
      // console.log("error : ", err);
    }
  };

  const paginateFlexible = () => {
    try {
      setFlexibleLoading(true);
      let allRec = [...flexibleFarmsAll];
      let start = pageLimit * currentPageFlexible - pageLimit;

      const filterRec = allRec.splice(start, pageLimit);
      let arr = [];
      for (let index = 0; index < filterRec.length; index++) {
        const element = filterRec[index];
        const token = Tokens.find((item) => item.address?.toLowerCase() === element.token?.toLowerCase());
        // const harvestToken = Tokens.find((item) => item.address?.toLowerCase() === element.harvestToken?.toLowerCase());
        let obj = {
          ind: start + index + 1,
          id: element.id,
          datetime: element.datetime,
          lockPer: ethers.utils.formatEther(element.lockPer),
          token: element.token,
          value: ethers.utils.formatEther(element.value),
          token: token,
          // harvestToken,
        };
        arr.push(obj);
      }

      setFlexibleFarms([...arr]);
      setFlexibleLoading(false);
    } catch (err) {
      setFlexibleLoading(false);
      // console.log("error in paginateFixed : ", err);
    }
  };

  useEffect(() => {
    paginateFlexible();
  }, [flexibleFarmsAll, currentPageFlexible]);

  useEffect(() => {
    if (Tokens) {
      setFlexibleLoading(true);
      getAllFarmFlexible();
    }
  }, [Tokens, provider, address]);

  // --------------------------------- Cross farmings start ---------------------------------
  const getAllFarmCrossFixed = async () => {
    try {
      setCrossFixedLoading(true);
      const fixed = getContractCrossFixed();
      const farms = await fixed.getHarvestOfAddress(address);
      const filter = farms.map((item) => {
        return {
          datetime: item?.datetime,
          harvestToken: item?.harvestToken,
          id: item?.id,
          lockPer: item?.lockPer,
          token: item?.token,
          user: item?.user,
          value: item?.value,
        };
      });
      const reverse = await filter.reverse();
      setCrossFixedFarmsAll([...reverse]);
      setPagesCrossFixed(Math.ceil(farms?.length / pageLimit));
    } catch (err) {
      setCrossFixedLoading(false);
      // console.log("error : ", err);
    }
  };

  const paginateCrossFixed = () => {
    try {
      setCrossFixedLoading(true);
      let allRec = [...crossFixedFarmsAll];
      let start = pageLimit * currentPageCrossFixed - pageLimit;
      const filterRec = allRec.splice(start, pageLimit);
      let arr = [];
      for (let index = 0; index < filterRec.length; index++) {
        const element = filterRec[index];

        const token = Tokens.find((item) => item.address?.toLowerCase() === element.token?.toLowerCase());
        const harvestToken = Tokens.find((item) => item.address?.toLowerCase() === element.harvestToken?.toLowerCase());
        let obj = {
          ind: start + index + 1,
          id: element.id,
          datetime: element.datetime,
          lockPer: ethers.utils.formatEther(element.lockPer),
          token: element.token,
          value: ethers.utils.formatEther(element.value),
          token: { ...token },
          harvestToken,
        };
        arr.push(obj);
      }

      setCrossFixedFarms([...arr]);
      setCrossFixedLoading(false);
    } catch (err) {
      setCrossFixedLoading(false);
      // console.log("error in paginateFixed : ", err);
    }
  };

  useEffect(() => {
    paginateCrossFixed();
  }, [crossFixedFarmsAll, currentPageCrossFixed]);

  useEffect(() => {
    if (Tokens) {
      setCrossFixedLoading(true);
      getAllFarmCrossFixed();
    }
  }, [Tokens, provider, address]);

  const getAllFarmCrossFlexible = async () => {
    try {
      setCrossFlexibleLoading(true);
      const flexible = getContractCrossFlexible();
      const farms = await flexible.getHarvestOfAddress(address);
      const filter = farms.map((item) => {
        return {
          datetime: item?.datetime,
          harvestToken: item?.harvestToken,
          id: item?.id,
          lockPer: item?.lockPer,
          token: item?.token,
          user: item?.user,
          value: item?.value,
        };
      });
      const reverse = await filter.reverse();
      setCrossFlexibleFarmsAll([...reverse]);
      setPagesCrossFlexible(Math.ceil(farms?.length / pageLimit));
    } catch (err) {
      setCrossFlexibleLoading(false);
      // console.log("error : ", err);
    }
  };

  const paginateCrossFlexible = () => {
    try {
      setCrossFlexibleLoading(true);
      let allRec = [...crossFlexibleFarmsAll];
      let start = pageLimit * currentPageCrossFlexible - pageLimit;

      const filterRec = allRec.splice(start, pageLimit);
      let arr = [];
      for (let index = 0; index < filterRec.length; index++) {
        const element = filterRec[index];
        const token = Tokens.find((item) => item.address?.toLowerCase() === element.token?.toLowerCase());
        const harvestToken = Tokens.find((item) => item.address?.toLowerCase() === element.harvestToken?.toLowerCase());
        let obj = {
          ind: start + index + 1,
          id: element.id,
          datetime: element.datetime,
          lockPer: ethers.utils.formatEther(element.lockPer),
          token: element.token,
          value: ethers.utils.formatEther(element.value),
          token: token,
          harvestToken,
        };
        arr.push(obj);
      }

      setCrossFlexibleFarms([...arr]);
      setCrossFlexibleLoading(false);
    } catch (err) {
      setCrossFlexibleLoading(false);
      // console.log("error in paginateFixed : ", err);
    }
  };

  useEffect(() => {
    paginateCrossFlexible();
  }, [flexibleFarmsAll, currentPageFlexible]);

  useEffect(() => {
    if (Tokens) {
      setFlexibleLoading(true);
      getAllFarmCrossFlexible();
    }
  }, [Tokens, provider, address]);
  // --------------------------------- Cross farmings start ---------------------------------

  const handleOpenModal = async (item, type) => {
    try {
      if (type === 0) {
        const contract = await getContractFixed();
        const rewardInfo = await contract.getFarmingRewardInfo(item.id);

        setModalInfo({
          ...item,
          dailyPer: ethers.utils.formatEther(rewardInfo.dailyPer),
          dailyReward: ethers.utils.formatEther(rewardInfo.dailyReward),
          day: rewardInfo?.day?.toString(),
          totalReward: ethers.utils.formatEther(rewardInfo.totalReward),
          harvestAfter: dateDifference(item.lastClaimed),
          unfarmAfter: dateDifference(Number(item.starttime) + Number(item.lockFor) * 30 * 24 * 60 * 60),
          harvestBtnDisable: Number(item.lastClaimed) * 1000 + 60 * 60 * 24 * 1000 > new Date().getTime() ? true : false,
          unfarmBtnDisable:
            Number(item.starttime) * 1000 + Number(item.lockFor) * 30 * 24 * 60 * 60 * 1000 > new Date().getTime() ? true : false,
          type,
        });
      } else {
        const contract = await getContractFlexible();
        const rewardInfo = await contract.getFarmingRewardInfo(item.id);

        setModalInfo({
          ...item,
          dailyPer: ethers.utils.formatEther(rewardInfo.dailyPer),
          dailyReward: ethers.utils.formatEther(rewardInfo.dailyReward),
          day: rewardInfo?.day?.toString(),
          totalReward: ethers.utils.formatEther(rewardInfo.totalReward),
          harvestAfter: dateDifference(item.lastClaimed),
          unfarmAfter: dateDifference(Number(item.starttime) + Number(item.lockFor) * 30 * 24 * 60 * 60),
          harvestBtnDisable: Number(item.lastClaimed) * 1000 + 60 * 60 * 24 * 1000 > new Date().getTime() ? true : false,
          unfarmBtnDisable:
            Number(item.starttime) * 1000 + Number(item.lockFor) * 30 * 24 * 60 * 60 * 1000 > new Date().getTime() ? true : false,
          type,
        });
      }
      setInfoModal(true);
    } catch (err) {
      // console.log("error in handleOpenModal : ", err);
    }
  };

  return (
    <>
      {/* row */}
      <div className="row">
        <div className="col-xxl-12">
          <div className="card">
            <div className="card-header border-0 pb-2 flex-wrap">
              {/* <div class="d-flex"> */}
              <h4 className="heading me-2">Harvest History</h4>
              <nav>
                <div className="order nav nav-tabs" id="nav-tab" role="tablist">
                  <button
                    className="nav-link active"
                    id="nav-order-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#fixed"
                    type="button"
                    role="tab"
                    aria-selected="true"
                  >
                    Fixed
                  </button>
                  <button
                    className="nav-link"
                    id="nav-histroy-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#flexible"
                    type="button"
                    role="tab"
                    aria-selected="false"
                  >
                    Flexible
                  </button>
                  <button
                    className="nav-link"
                    id="nav-histroy-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#crossfixed"
                    type="button"
                    role="tab"
                    aria-selected="false"
                  >
                    Hybrid Heal
                  </button>
                  <button
                    className="nav-link"
                    id="nav-histroy-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#crossflexible"
                    type="button"
                    role="tab"
                    aria-selected="false"
                  >
                    Hybrid Relax
                  </button>
                </div>
              </nav>
              {/* </div> */}
            </div>
            <div className="card-body pt-2">
              {isConnected ? (
                <div className="tab-content" id="nav-tabContent">
                  <FixedTokens
                    farms={fixedFarms}
                    loading={fixedLoading}
                    handleOpenModal={handleOpenModal}
                    currentPage={currentPageFixed}
                    setCurrentPage={setCurrentPageFixed}
                    pages={pagesFixed}
                  />
                  <FlexibleTokens
                    farms={flexibleFarms}
                    loading={flexibleLoading}
                    handleOpenModal={handleOpenModal}
                    currentPage={currentPageFixed}
                    setCurrentPage={setCurrentPageFixed}
                    pages={pagesFlexible}
                  />
                  <CrossFixedTokens
                    farms={crossFixedFarms}
                    loading={crossFixedLoading}
                    handleOpenModal={handleOpenModal}
                    currentPage={currentPageCrossFixed}
                    setCurrentPage={setCurrentPageCrossFixed}
                    pages={pagesCrossFixed}
                  />
                  <CrossFlexibleTokens
                    farms={crossFlexibleFarms}
                    loading={crossFlexibleLoading}
                    handleOpenModal={handleOpenModal}
                    currentPage={currentPageCrossFlexible}
                    setCurrentPage={setCurrentPageCrossFlexible}
                    pages={pagesCrossFlexible}
                  />
                </div>
              ) : (
                <div className="row d-flex justify-content-center">
                  <div className="col-md-6 col-12">
                    <button type="button" className="btn btn-primary w-100" onClick={connectWallet}>
                      Connect Wallet
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <InfoModal open={infoModal} setOpen={setInfoModal} from="farm" info={modalInfo} />
    </>
  );
}
