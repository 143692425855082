import React from "react";
import { formatAddress, formatAmount } from "../../../functions/common";
import { useNavigate } from "react-router-dom";
import CopyBtn from "../../Coman/CopyBtn";

const CrossFixedTokens = ({ tokens }) => {
  const navigate = useNavigate();

  return (
    <div className="tab-pane fade show" id="crossfixed" role="tabpanel" aria-labelledby="nav-order-tab">
      <div className="table-responsive dataTablehistory">
        <table id="example" className="table shadow-hover display" style={{ minWidth: "845px" }}>
          <thead>
            <tr role="row">
              <th
                className="sorting"
                tabIndex={0}
                aria-controls="example3"
                rowSpan={1}
                colSpan={1}
                aria-label=": activate to sort column ascending"
                style={{ width: "38.5312px" }}
              >
                Logo
              </th>
              <th
                className="sorting"
                tabIndex={0}
                aria-controls="example3"
                rowSpan={1}
                colSpan={1}
                aria-label="Name: activate to sort column ascending"
                style={{ width: "160.719px" }}
              >
                Name
              </th>
              <th
                className="sorting"
                tabIndex={0}
                aria-controls="example3"
                rowSpan={1}
                colSpan={1}
                aria-label="Department: activate to sort column ascending"
                style={{ width: "192.281px" }}
              >
                Address
              </th>
              <th
                className="sorting"
                tabIndex={0}
                aria-controls="example3"
                rowSpan={1}
                colSpan={1}
                aria-label="Gender: activate to sort column ascending"
                style={{ width: "92.5px" }}
              >
                Total Farming
              </th>
              <th
                className="sorting"
                tabIndex={0}
                aria-controls="example3"
                rowSpan={1}
                colSpan={1}
                aria-label="Department: activate to sort column ascending"
                style={{ width: "192.281px" }}
              >
                TVL
              </th>
            </tr>
          </thead>
          <tbody>
            <tr role="row" className="odd c-pointer" key={tokens?.address}>
              <td onClick={() => navigate({ pathname: "/crossfixed", search: `?token=${tokens?.address}` })}>
                <img className="rounded-circle" style={{ maxWidth: "40px" }} src={tokens?.icon} alt="" />
              </td>
              <td onClick={() => navigate({ pathname: "/crossfixed", search: `?token=${tokens?.address}` })}>
                {tokens?.symbol}({tokens?.name})
              </td>
              <td className>
                <div className="d-flex">
                  {formatAddress(tokens?.address)} <CopyBtn text={tokens?.address} />
                </div>
              </td>
              <td className>
                {tokens?.totalFaming ? (
                  formatAmount(tokens?.totalFaming) + " " + tokens?.symbol
                ) : (
                  <div className="d-flex">
                    <div class="spinner-border text-primary" role="status" style={{ height: "25px", width: "25px" }}>
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  </div>
                )}
              </td>
              <td className>
                {tokens?.tvl ? (
                  formatAmount(tokens?.tvl) + "$"
                ) : (
                  <div className="d-flex">
                    <div class="spinner-border text-primary" role="status" style={{ height: "25px", width: "25px" }}>
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  </div>
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CrossFixedTokens;
