import React, { useContext, useEffect, useState } from "react";
import { Tokens } from "../../config/config";
import FixedTokens from "./FixedTokens";
import FlexibleTokens from "./FlexibleTokens";
import CrossFixedTokens from "./CrossFixedTokens";
import CrossFlexibleTokens from "./CrossFlexibleTokens";
import { appContext } from "../../AppContext";
import { ethers } from "ethers";
import InfoModal from "../Coman/InfoModal";
import { dateDifference } from "../../functions/common";

export default function UserTable() {
  const {
    getContractFixed,
    getContractCrossFixed,
    getContractCrossFlexible,
    getContractFlexible,
    provider,
    address,
    isConnected,
    connectWallet,
  } = useContext(appContext);
  const pageLimit = 10;
  const [fixedFarms, setFixedFarms] = useState([]);
  const [flexibleFarms, setFlexibleFarms] = useState([]);

  const [crossFixedFarms, setCrossFixedFarms] = useState([]);
  const [crossFlexibleFarms, setCrossFlexibleFarms] = useState([]);

  const [fixedFarmsAll, setFixedFarmsAll] = useState([]);
  const [flexibleFarmsAll, setFlexibleFarmsAll] = useState([]);

  const [crossFixedFarmsAll, setCrossFixedFarmsAll] = useState([]);
  const [crossFlexibleFarmsAll, setCrossFlexibleFarmsAll] = useState([]);

  const [fixedLoading, setFixedLoading] = useState(true);
  const [flexibleLoading, setFlexibleLoading] = useState(true);

  const [crossFixedLoading, setCrossFixedLoading] = useState(true);
  const [crossFlexibleLoading, setCrossFlexibleLoading] = useState(true);

  const [currentPageFixed, setCurrentPageFixed] = useState(1);
  const [currentPageFlexible, setCurrentPageFlexible] = useState(1);

  const [currentPageCrossFixed, setCurrentPageCrossFixed] = useState(1);
  const [currentPageCrossFlexible, setCurrentPageCrossFlexible] = useState(1);

  const [pagesFixed, setPagesFixed] = useState(0);
  const [pagesFlexible, setPagesFlexible] = useState(0);

  const [pagesCrossFixed, setPagesCrossFixed] = useState(0);
  const [pagesCrossFlexible, setPagesCrossFlexible] = useState(0);

  const [infoModal, setInfoModal] = useState(false);
  const [modalInfo, setModalInfo] = useState(null);

  const getAllFarmFixed = async () => {
    try {
      setFixedLoading(true);
      const contract = getContractFixed();
      const farms = await contract.getFarmingOfAddress(address);
      const formatedArr = farms.map((item) => {
        return {
          endtime: item.endtime,
          id: item.id,
          lastClaimed: item.lastClaimed,
          lockFor: item.lockFor,
          starttime: item.starttime,
          status: item.status,
          token: item.token,
          user: item.user,
          value: item.value,
        };
      });
      const reverseArr = await formatedArr.reverse();
      setFixedFarmsAll([...reverseArr]);
      setPagesFixed(Math.ceil(farms?.length / pageLimit));
    } catch (err) {
      setFixedLoading(false);
      // console.log("error : ", err);
    }
  };

  const paginateFixed = () => {
    try {
      setFixedLoading(true);
      let allRec = [...fixedFarmsAll];
      let start = pageLimit * currentPageFixed - pageLimit;
      const filterRec = allRec.splice(start, pageLimit);
      let arr = [];
      for (let index = 0; index < filterRec.length; index++) {
        const element = filterRec[index];

        const token = Tokens.find((item) => item.address?.toLowerCase() === element.token?.toLowerCase());
        const month = element.lockFor?.toString();
        const endtime = Number(element.starttime?.toString()) + Number(month) * 30 * 24 * 60 * 60;
        let obj = {
          ind: start + index + 1,
          id: element.id,
          // harvestToken: element.harvestToken,
          lastClaimed: element.lastClaimed?.toString(),
          lockFor: month,
          starttime: element.starttime?.toString(),
          endtime: endtime?.toString(),
          unfarmdate: element?.endtime?.toString(),
          token: element.token,
          status: element.status,
          user: element.user,
          value: ethers.utils.formatEther(element.value),
          icon: token.icon,
          name: token.name,
          symbol: token.symbol,
        };
        arr.push(obj);
      }
      setFixedFarms([...arr]);
      setFixedLoading(false);
    } catch (err) {
      setFixedLoading(false);
      // console.log("error in paginateFixed : ", err);
    }
  };

  useEffect(() => {
    paginateFixed();
  }, [fixedFarmsAll, currentPageFixed]);

  useEffect(() => {
    if (Tokens) {
      setFixedLoading(true);
      getAllFarmFixed();
    }
  }, [Tokens, provider, address]);

  const getAllFarmFlexible = async () => {
    try {
      setFlexibleLoading(true);
      const contract = getContractFlexible();
      const farms = await contract.getFarmingOfAddress(address);
      const formatedArr = farms.map((item) => {
        return {
          endtime: item.endtime,
          id: item.id,
          lastClaimed: item.lastClaimed,
          lockFor: item.lockFor,
          starttime: item.starttime,
          status: item.status,
          token: item.token,
          user: item.user,
          value: item.value,
        };
      });
      const reverseArr = await formatedArr.reverse();
      setFlexibleFarmsAll([...reverseArr]);
      setPagesFlexible(Math.ceil(farms?.length / pageLimit));
    } catch (err) {
      setFlexibleLoading(false);
    }
  };

  const paginateFlexible = () => {
    try {
      setFlexibleLoading(true);
      let allRec = [...flexibleFarmsAll];
      let start = pageLimit * currentPageFlexible - pageLimit;

      const filterRec = allRec.splice(start, pageLimit);
      let arr = [];
      for (let index = 0; index < filterRec.length; index++) {
        const element = filterRec[index];

        const token = Tokens.find((item) => item.address?.toLowerCase() === element.token?.toLowerCase());
        const month = element.lockFor?.toString();
        const endtime = Number(element.starttime?.toString()) + Number(month) * 30 * 24 * 60 * 60;
        let obj = {
          ind: start + index + 1,
          id: element.id,
          // harvestToken: element.harvestToken,
          lastClaimed: element.lastClaimed?.toString(),
          lockFor: month,
          starttime: element.starttime?.toString(),
          endtime: endtime,
          unfarmdate: element?.endtime?.toString(),
          token: element.token,
          status: element.status,
          user: element.user,
          value: ethers.utils.formatEther(element.value),
          icon: token.icon,
          name: token.name,
          symbol: token.symbol,
        };
        arr.push(obj);
      }

      setFlexibleFarms([...arr]);
      setFlexibleLoading(false);
    } catch (err) {
      setFlexibleLoading(false);
      // console.log("error in paginateFixed : ", err);
    }
  };

  useEffect(() => {
    paginateFlexible();
  }, [flexibleFarmsAll, currentPageFlexible]);

  useEffect(() => {
    if (Tokens) {
      setFlexibleLoading(true);
      getAllFarmFlexible();
    }
  }, [Tokens, provider, address]);

  // --------------------------------- Cross farmings start ---------------------------------

  const getAllFarmCrossFixed = async () => {
    try {
      setCrossFixedLoading(true);
      const contract = getContractCrossFixed();
      const farms = await contract.getFarmingOfAddress(address);
      const formatedArr = farms.map((item) => {
        return {
          endtime: item.endtime,
          harvestToken: item.harvestToken,
          id: item.id,
          lastClaimed: item.lastClaimed,
          lockFor: item.lockFor,
          starttime: item.starttime,
          status: item.status,
          token: item.token,
          user: item.user,
          value: item.value,
        };
      });
      const reverseArr = await formatedArr.reverse();
      setCrossFixedFarmsAll([...reverseArr]);
      setPagesCrossFixed(Math.ceil(farms?.length / pageLimit));
    } catch (err) {
      setCrossFixedLoading(false);
      // console.log("error : ", err);
    }
  };

  const paginateCrossFixed = () => {
    try {
      setCrossFixedLoading(true);
      let allRec = [...crossFixedFarmsAll];
      let start = pageLimit * currentPageCrossFixed - pageLimit;
      const filterRec = allRec.splice(start, pageLimit);
      let arr = [];
      for (let index = 0; index < filterRec.length; index++) {
        const element = filterRec[index];

        const token = Tokens.find((item) => item.address?.toLowerCase() === element.token?.toLowerCase());
        const month = element.lockFor?.toString();
        const endtime = Number(element.starttime?.toString()) + Number(month) * 30 * 24 * 60 * 60;
        let obj = {
          ind: start + index + 1,
          id: element.id,
          harvestToken: element.harvestToken,
          lastClaimed: element.lastClaimed?.toString(),
          lockFor: element.lockFor?.toString(),
          starttime: element.starttime?.toString(),
          endtime: endtime?.toString(),
          unfarmdate: element?.endtime?.toString(),
          token: element.token,
          status: element.status,
          user: element.user,
          value: ethers.utils.formatEther(element.value),
          icon: token.icon,
          name: token.name,
          symbol: token.symbol,
        };
        arr.push(obj);
      }
      setCrossFixedFarms([...arr]);
      setCrossFixedLoading(false);
    } catch (err) {
      setCrossFixedLoading(false);
      // console.log("error in paginateFixed : ", err);
    }
  };

  useEffect(() => {
    paginateCrossFixed();
  }, [crossFixedFarmsAll, currentPageCrossFixed]);

  useEffect(() => {
    if (Tokens) {
      setCrossFixedLoading(true);
      getAllFarmCrossFixed();
    }
  }, [Tokens, provider, address]);

  const getAllFarmCrossFlexible = async () => {
    try {
      setCrossFlexibleLoading(true);
      const contract = getContractCrossFlexible();
      const farms = await contract.getFarmingOfAddress(address);
      const formatedArr = farms.map((item) => {
        return {
          endtime: item.endtime,
          harvestToken: item.harvestToken,
          id: item.id,
          lastClaimed: item.lastClaimed,
          lockFor: item.lockFor,
          starttime: item.starttime,
          status: item.status,
          token: item.token,
          user: item.user,
          value: item.value,
        };
      });
      const reverseArr = await formatedArr.reverse();
      setCrossFlexibleFarmsAll([...reverseArr]);
      setPagesCrossFlexible(Math.ceil(farms?.length / pageLimit));
    } catch (err) {
      setCrossFlexibleLoading(false);
    }
  };

  const paginateCrossFlexible = () => {
    try {
      setCrossFlexibleLoading(true);
      let allRec = [...crossFlexibleFarmsAll];
      let start = pageLimit * currentPageCrossFlexible - pageLimit;

      const filterRec = allRec.splice(start, pageLimit);
      let arr = [];
      for (let index = 0; index < filterRec.length; index++) {
        const element = filterRec[index];

        const token = Tokens.find((item) => item.address?.toLowerCase() === element.token?.toLowerCase());
        const month = element.lockFor?.toString();
        const endtime = Number(element.starttime?.toString()) + Number(month) * 30 * 24 * 60 * 60;
        let obj = {
          ind: start + index + 1,
          id: element.id,
          harvestToken: element.harvestToken,
          lastClaimed: element.lastClaimed?.toString(),
          lockFor: element.lockFor?.toString(),
          starttime: element.starttime?.toString(),
          endtime: endtime?.toString(),
          unfarmdate: element?.endtime?.toString(),
          token: element.token,
          status: element.status,
          user: element.user,
          value: ethers.utils.formatEther(element.value),
          icon: token.icon,
          name: token.name,
          symbol: token.symbol,
        };
        arr.push(obj);
      }

      setCrossFlexibleFarms([...arr]);
      setCrossFlexibleLoading(false);
    } catch (err) {
      setCrossFlexibleLoading(false);
      // console.log("error in paginateFixed : ", err);
    }
  };

  useEffect(() => {
    paginateCrossFlexible();
  }, [crossFlexibleFarmsAll, currentPageCrossFlexible]);

  useEffect(() => {
    if (Tokens) {
      setCrossFlexibleLoading(true);
      getAllFarmCrossFlexible();
    }
  }, [Tokens, provider, address]);
  // --------------------------------- Cross farmings end ---------------------------------

  return (
    <>
      {/* row */}
      <div className="row">
        <div className="col-xxl-12">
          <div className="card">
            <div className="card-header border-0 pb-2 flex-wrap">
              {/* <div class="d-flex"> */}
              <h4 className="heading me-2">History</h4>
              <nav>
                <div className="order nav nav-tabs" id="nav-tab" role="tablist">
                  <button
                    className="nav-link active"
                    id="nav-order-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#fixed"
                    type="button"
                    role="tab"
                    aria-selected="true"
                  >
                    Fixed
                  </button>
                  <button
                    className="nav-link"
                    id="nav-histroy-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#flexible"
                    type="button"
                    role="tab"
                    aria-selected="false"
                  >
                    Flexible
                  </button>
                  <button
                    className="nav-link"
                    id="nav-histroy-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#crossfixed"
                    type="button"
                    role="tab"
                    aria-selected="false"
                  >
                    Hybrid Heal
                  </button>
                  <button
                    className="nav-link"
                    id="nav-histroy-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#crossflexible"
                    type="button"
                    role="tab"
                    aria-selected="false"
                  >
                    Hybrid Relax
                  </button>
                </div>
              </nav>
              {/* </div> */}
            </div>
            <div className="card-body pt-2">
              {isConnected ? (
                <div className="tab-content" id="nav-tabContent">
                  <FixedTokens
                    farms={fixedFarms}
                    loading={fixedLoading}
                    currentPage={currentPageFixed}
                    setCurrentPage={setCurrentPageFixed}
                    pages={pagesFixed}
                  />
                  <FlexibleTokens
                    farms={flexibleFarms}
                    loading={flexibleLoading}
                    currentPage={currentPageFlexible}
                    setCurrentPage={setCurrentPageFlexible}
                    pages={pagesFlexible}
                  />
                  <CrossFixedTokens
                    farms={crossFixedFarms}
                    loading={crossFixedLoading}
                    currentPage={currentPageCrossFixed}
                    setCurrentPage={setCurrentPageCrossFixed}
                    pages={pagesCrossFixed}
                  />
                  <CrossFlexibleTokens
                    farms={crossFlexibleFarms}
                    loading={crossFlexibleLoading}
                    currentPage={currentPageCrossFlexible}
                    setCurrentPage={setCurrentPageCrossFlexible}
                    pages={pagesCrossFlexible}
                  />
                </div>
              ) : (
                <div className="row d-flex justify-content-center">
                  <div className="col-md-6 col-12">
                    <button type="button" className="btn btn-primary w-100" onClick={connectWallet}>
                      Connect Wallet
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
