import React from "react";
import Sidebar from "../Coman/Sidebar";
import UserTable from "./UserTable";

export default function Farm() {
  return (
    <>
      <div id="main-wrapper" className="show">
        <Sidebar />
        <div class="content-body" style={{ minHeight: "0px" }}>
          <div class="container-fluid">
            <UserTable />
          </div>
        </div>
      </div>
    </>
  );
}
