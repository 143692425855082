import React from "react";
import { formatAddress, formatAmount, formatDate } from "../../functions/common";
import { useNavigate } from "react-router-dom";
import CopyBtn from "../Coman/CopyBtn";
import Pagination from "../Coman/Pagination";

const FixedTokens = ({ farms, loading, handleOpenModal, currentPage, setCurrentPage, pages }) => {
  return (
    <div className="tab-pane fade show active" id="fixed" role="tabpanel" aria-labelledby="nav-order-tab">
      <div className="table-responsive dataTablehistory">
        {loading ? (
          <div className="d-flex justify-content-center my-5">
            <div class="spinner-border text-primary" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : (
          <table id="example" className="table shadow-hover display" style={{ minWidth: "845px" }}>
            <thead>
              <tr role="row">
                <th
                  className="sorting"
                  tabIndex={0}
                  aria-controls="example3"
                  rowSpan={1}
                  colSpan={1}
                  aria-label=": activate to sort column ascending"
                  style={{ width: "38.5312px" }}
                >
                  Action
                </th>
                <th
                  className="sorting"
                  tabIndex={0}
                  aria-controls="example3"
                  rowSpan={1}
                  colSpan={1}
                  aria-label=": activate to sort column ascending"
                  style={{ width: "38.5312px" }}
                >
                  No.
                </th>
                <th
                  className="sorting"
                  tabIndex={0}
                  aria-controls="example3"
                  rowSpan={1}
                  colSpan={1}
                  aria-label=": activate to sort column ascending"
                  style={{ width: "38.5312px" }}
                >
                  Logo
                </th>
                <th
                  className="sorting"
                  tabIndex={0}
                  aria-controls="example3"
                  rowSpan={1}
                  colSpan={1}
                  aria-label="Name: activate to sort column ascending"
                  style={{ width: "160.719px" }}
                >
                  Name
                </th>
                <th
                  className="sorting"
                  tabIndex={0}
                  aria-controls="example3"
                  rowSpan={1}
                  colSpan={1}
                  aria-label="Gender: activate to sort column ascending"
                  style={{ width: "92.5px" }}
                >
                  Token
                </th>
                {/* <th
                  className="sorting"
                  tabIndex={0}
                  aria-controls="example3"
                  rowSpan={1}
                  colSpan={1}
                  aria-label="Gender: activate to sort column ascending"
                  style={{ width: "92.5px" }}
                >
                  Harvest Token
                </th> */}
                <th
                  className="sorting"
                  tabIndex={0}
                  aria-controls="example3"
                  rowSpan={1}
                  colSpan={1}
                  aria-label="Gender: activate to sort column ascending"
                  style={{ width: "92.5px" }}
                >
                  Lock Time
                </th>
                <th
                  className="sorting"
                  tabIndex={0}
                  aria-controls="example3"
                  rowSpan={1}
                  colSpan={1}
                  aria-label="Gender: activate to sort column ascending"
                  style={{ width: "92.5px" }}
                >
                  Amount
                </th>
                <th
                  className="sorting"
                  tabIndex={0}
                  aria-controls="example3"
                  rowSpan={1}
                  colSpan={1}
                  aria-label="Gender: activate to sort column ascending"
                  style={{ width: "92.5px" }}
                >
                  Start Date
                </th>
                <th
                  className="sorting"
                  tabIndex={0}
                  aria-controls="example3"
                  rowSpan={1}
                  colSpan={1}
                  aria-label="Gender: activate to sort column ascending"
                  style={{ width: "92.5px" }}
                >
                  End Date
                </th>
                <th
                  className="sorting"
                  tabIndex={0}
                  aria-controls="example3"
                  rowSpan={1}
                  colSpan={1}
                  aria-label="Gender: activate to sort column ascending"
                  style={{ width: "92.5px" }}
                >
                  Unfarm Date
                </th>
                <th
                  className="sorting"
                  tabIndex={0}
                  aria-controls="example3"
                  rowSpan={1}
                  colSpan={1}
                  aria-label="Gender: activate to sort column ascending"
                  style={{ width: "92.5px" }}
                >
                  Last Harvest
                </th>

                <th
                  className="sorting"
                  tabIndex={0}
                  aria-controls="example3"
                  rowSpan={1}
                  colSpan={1}
                  aria-label="Gender: activate to sort column ascending"
                  style={{ width: "92.5px" }}
                >
                  Status
                </th>
              </tr>
            </thead>
            <tbody>
              {farms?.length
                ? farms?.map((item, ind) => {
                    return (
                      <tr role="row" className="odd c-pointer" key={item.address}>
                        <td>
                          {item.status ? (
                            <button className="btn btn-primary px-3 py-2" onClick={() => handleOpenModal(item.id, 0)}>
                              Harvest
                            </button>
                          ) : (
                            <p className="m-0 text-success">Completed</p>
                          )}
                        </td>
                        <td>{item.ind}</td>
                        <td>
                          <img className="rounded-circle" style={{ maxWidth: "40px" }} src={item.icon} alt="" />
                        </td>
                        <td>
                          {item.symbol}({item.name})
                        </td>
                        <td>
                          <div className="d-flex">
                            {formatAddress(item.token)} <CopyBtn text={item.token} />
                          </div>
                        </td>
                        <td>{item.lockFor} month</td>
                        <td>
                          {formatAmount(item.value)} {item.symbol}
                        </td>
                        <td className>{formatDate(item.starttime)}</td>
                        <td className>{item.endtime !== "0" ? formatDate(item.endtime) : "-"}</td>
                        <td className>{item.unfarmdate !== "0" ? formatDate(item.unfarmdate) : "-"}</td>
                        <td className>{item.lastClaimed !== item.starttime ? formatDate(item.lastClaimed) : "-"}</td>
                        <td className>
                          {item.status ? <span class="badge bg-success">Active</span> : <span class="badge bg-danger">Deactive</span>}
                        </td>
                      </tr>
                    );
                  })
                : ""}
            </tbody>
          </table>
        )}
        {!farms.length && !loading && (
          <div>
            <p className="text-center mt-2">No items found.</p>
          </div>
        )}
        <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} pages={pages} />
      </div>
    </div>
  );
};

export default FixedTokens;
