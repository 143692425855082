import React, { useEffect, useContext, useState } from "react";
import { appContext } from "../../AppContext";
import { ethers } from "ethers";
import { Tokens } from "../../config/config";
import FixedTokens from "./FixedTokens";
import FlexibleTokens from "./FlexibleTokens";
import CrossFixedTokens from "./CrossFixedTokens";
import CrossFlexibleTokens from "./CrossFlexibleTokens";

export default function DashboardMain() {
  const [fixedTokens, setFixedTokens] = useState([]);
  const [flexibleTokens, setFlexibleTokens] = useState([]);
  const [fixedLoading, setFixedLoading] = useState(true);
  const [flexibleLoading, setFlexibleLoading] = useState(true);

  const [totalCrossFixedFarming, setTotalCrossFixedFarming] = useState("0");
  const [totalCrossFlexibleFarming, setTotalCrossFlexibleFarming] = useState("0");

  const [totalFixedHarvest, setTotalFixedHarvest] = useState("0");
  const [totalFlexibleHarvest, setTotalFlexibleHarvest] = useState("0");

  const [crossFixedTokens, setCrossFixedTokens] = useState([]);
  const [crossFlexibleTokens, setCrossFlexibleTokens] = useState([]);
  const [crossFixedLoading, setCrossFixedLoading] = useState(true);
  const [crossFlexibleLoading, setCrossFlexibleLoading] = useState(true);

  const {
    getContractFixed,
    getContractFlexible,
    provider,
    getContractCrossFixed,
    getContractCrossFlexible,
    isConnected,
    connectWallet,
    address,
  } = useContext(appContext);

  const getTokensTotalFixed = async () => {
    try {
      const fixed = getContractFixed();
      let temparr = [];

      let tokenHarvest = await fixed.getHarvestOfAddress(address);
      let totalHarvest = 0;

      for (let index = 0; index < tokenHarvest.length; index++) {
        const ele = tokenHarvest[index];
        totalHarvest += Number(ethers.utils.formatEther(ele.value));
      }

      setTotalFixedHarvest(totalHarvest);

      for (let i = 0; i < Tokens.length; i++) {
        let element = Tokens[i];
        let totalWei = await fixed.getTotalAddressFarmingOfToken(element?.address, address);

        let obj = { ...element, totalFaming: ethers.utils.formatEther(totalWei) };
        temparr.push(obj);
      }

      setFixedTokens([...temparr]);
      setFixedLoading(false);
    } catch (err) {
      // console.log("error : ", err);
      setFixedLoading(false);
    }
  };

  useEffect(() => {
    if (Tokens && provider && address) {
      setFixedLoading(true);
      getTokensTotalFixed();
    }
  }, [Tokens, provider, address]);

  const getTokensTotalFlexible = async () => {
    try {
      const fixed = getContractFlexible();
      let temparr = [];
      let tokenHarvest = await fixed.getHarvestOfAddress(address);
      let totalHarvest = 0;
      for (let index = 0; index < tokenHarvest.length; index++) {
        const ele = tokenHarvest[index];
        totalHarvest += Number(ethers.utils.formatEther(ele.value));
      }

      setTotalFlexibleHarvest(totalHarvest);

      for (let i = 0; i < Tokens.length; i++) {
        let element = Tokens[i];
        let totalWei = await fixed.getTotalAddressFarmingOfToken(element?.address, address);

        let obj = { ...element, totalFaming: ethers.utils.formatEther(totalWei) };
        temparr.push(obj);
      }

      setFlexibleTokens([...temparr]);
      setFlexibleLoading(false);
    } catch (err) {
      // console.log("error : ", err);
      setFlexibleLoading(false);
    }
  };

  useEffect(() => {
    if (Tokens && provider) {
      setFlexibleLoading(true);
      getTokensTotalFlexible();
    }
  }, [Tokens, provider]);

  // ------------ cross contract ------------
  const getTokensTotalCrossFixed = async () => {
    try {
      const contract = getContractCrossFixed();
      const totalFarmImWei = await contract.getTotalAddressFarming(address);
      setTotalCrossFixedFarming(ethers.utils.formatEther(totalFarmImWei));

      let temparr = [];
      for (let i = 0; i < Tokens.length; i++) {
        let element = Tokens[i];
        let totalWei = await contract.getTotalAddressHarvestOfToken(element.address, address);

        let obj = { ...element, totalHarvest: ethers.utils.formatEther(totalWei) };
        temparr.push(obj);
      }

      setCrossFixedTokens([...temparr]);
      setCrossFixedLoading(false);
    } catch (err) {
      // console.log("error : ", err);
    }
  };

  useEffect(() => {
    if (Tokens && provider) {
      setCrossFixedLoading(true);
      getTokensTotalCrossFixed();
    }
  }, [Tokens, provider, address]);

  const getTokensTotalCrossFlexible = async () => {
    try {
      const contract = getContractCrossFlexible();
      const totalFarmImWei = await contract.getTotalAddressFarming(address);
      setTotalCrossFlexibleFarming(ethers.utils.formatEther(totalFarmImWei));

      let temparr = [];
      for (let i = 0; i < Tokens.length; i++) {
        let element = Tokens[i];
        let totalWei = await contract.getTotalAddressHarvestOfToken(element.address, address);

        let obj = { ...element, totalHarvest: ethers.utils.formatEther(totalWei) };
        temparr.push(obj);
      }

      setCrossFlexibleTokens([...temparr]);
      setCrossFlexibleLoading(false);
    } catch (err) {
      // console.log("error : ", err);
    }
  };

  useEffect(() => {
    if (Tokens && provider) {
      setCrossFlexibleLoading(true);
      getTokensTotalCrossFlexible();
    }
  }, [Tokens, provider]);
  return (
    <>
      <div className="content-body">
        {/* row */}
        <div className="container-fluid">
          {/* Row */}
          <div className="row">
            <div className="col-xl-12">
              {/* Row */}
              <div className="row">
                {/* column */}
                <div className="d-flex justify-content-end mb-3">
                  <nav>
                    <div className="order nav nav-tabs" id="nav-tab" role="tablist">
                      <button
                        className="nav-link active"
                        id="nav-order-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#fixed"
                        type="button"
                        role="tab"
                        aria-selected="true"
                      >
                        Fixed
                      </button>
                      <button
                        className="nav-link"
                        id="nav-histroy-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#flexible"
                        type="button"
                        role="tab"
                        aria-selected="false"
                      >
                        Flexible
                      </button>
                      <button
                        className="nav-link"
                        id="nav-histroy-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#crossfixed"
                        type="button"
                        role="tab"
                        aria-selected="false"
                      >
                        Hybrid Heal
                      </button>
                      <button
                        className="nav-link"
                        id="nav-histroy-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#crossflexible"
                        type="button"
                        role="tab"
                        aria-selected="false"
                      >
                        Hybrid Relax
                      </button>
                    </div>
                  </nav>
                </div>
                <div className="tab-content" id="nav-tabContent">
                  {isConnected ? (
                    <>
                      <FixedTokens tokens={fixedTokens} totalHarvest={totalFixedHarvest} loading={fixedLoading} />
                      <FlexibleTokens tokens={flexibleTokens} totalHarvest={totalFlexibleHarvest} loading={flexibleLoading} />
                      <CrossFixedTokens tokens={crossFixedTokens} totalFixedFarming={totalCrossFixedFarming} loading={crossFixedLoading} />
                      <CrossFlexibleTokens
                        tokens={crossFlexibleTokens}
                        totalFixedFarming={totalCrossFlexibleFarming}
                        loading={crossFlexibleLoading}
                      />
                    </>
                  ) : (
                    <div className="row d-flex justify-content-center">
                      <div className="col-md-6 col-12">
                        <button type="button" className="btn btn-primary w-100" onClick={connectWallet}>
                          Connect Wallet
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
