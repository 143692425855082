import React from "react";
import { formatAddress, formatAmount } from "../../../functions/common";
import { useNavigate } from "react-router-dom";
import CopyBtn from "../../Coman/CopyBtn";

const FixedTokens = ({ tokens }) => {
  const navigate = useNavigate();

  return (
    <div className="tab-pane fade show active" id="fixed" role="tabpanel" aria-labelledby="nav-order-tab">
      <div className="table-responsive dataTablehistory">
        <table id="example" className="table shadow-hover display" style={{ minWidth: "845px" }}>
          <thead>
            <tr role="row">
              <th
                className="sorting"
                tabIndex={0}
                aria-controls="example3"
                rowSpan={1}
                colSpan={1}
                aria-label=": activate to sort column ascending"
                style={{ width: "38.5312px" }}
              >
                Logo
              </th>
              <th
                className="sorting"
                tabIndex={0}
                aria-controls="example3"
                rowSpan={1}
                colSpan={1}
                aria-label="Name: activate to sort column ascending"
                style={{ width: "160.719px" }}
              >
                Name
              </th>
              <th
                className="sorting"
                tabIndex={0}
                aria-controls="example3"
                rowSpan={1}
                colSpan={1}
                aria-label="Department: activate to sort column ascending"
                style={{ width: "192.281px" }}
              >
                Address
              </th>
              <th
                className="sorting"
                tabIndex={0}
                aria-controls="example3"
                rowSpan={1}
                colSpan={1}
                aria-label="Department: activate to sort column ascending"
                style={{ width: "192.281px" }}
              >
                APY
              </th>
              <th
                className="sorting"
                tabIndex={0}
                aria-controls="example3"
                rowSpan={1}
                colSpan={1}
                aria-label="Department: activate to sort column ascending"
                style={{ width: "192.281px" }}
              >
                Daily
              </th>
              <th
                className="sorting"
                tabIndex={0}
                aria-controls="example3"
                rowSpan={1}
                colSpan={1}
                aria-label="Gender: activate to sort column ascending"
                style={{ width: "92.5px" }}
              >
                Total Farming
              </th>
              <th
                className="sorting"
                tabIndex={0}
                aria-controls="example3"
                rowSpan={1}
                colSpan={1}
                aria-label="Department: activate to sort column ascending"
                style={{ width: "192.281px" }}
              >
                TVL
              </th>
            </tr>
          </thead>
          <tbody>
            {tokens?.length
              ? tokens?.map((item, ind) => {
                  return (
                    <tr role="row" className="odd c-pointer" key={item.address}>
                      <td onClick={() => navigate({ pathname: "/fixed", search: `?token=${item.address}` })}>
                        <img className="rounded-circle" style={{ maxWidth: "40px" }} src={item.icon} alt="" />
                      </td>
                      <td onClick={() => navigate({ pathname: "/fixed", search: `?token=${item.address}` })}>
                        {item.symbol}({item.name})
                      </td>
                      <td className>
                        <div className="d-flex">
                          {formatAddress(item.address)} <CopyBtn text={item.address} />
                        </div>
                      </td>
                      <td className>
                        {item?.tokenApy ? (
                          item?.tokenApy?.map((item) => {
                            return (
                              <>
                                <small>
                                  {item.month} month ({item.apy}%)
                                </small>
                                <br />
                              </>
                            );
                          })
                        ) : (
                          <div className="d-flex">
                            <div class="spinner-border text-primary" role="status" style={{ height: "25px", width: "25px" }}>
                              <span class="visually-hidden">Loading...</span>
                            </div>
                          </div>
                        )}
                      </td>
                      <td className>
                        {item?.tokenApy ? (
                          item?.tokenApy?.map((item) => {
                            return (
                              <>
                                <small>
                                  {item.month} month ({formatAmount(Number(item.apy) / 360)}%)
                                </small>
                                <br />
                              </>
                            );
                          })
                        ) : (
                          <div className="d-flex">
                            <div class="spinner-border text-primary" role="status" style={{ height: "25px", width: "25px" }}>
                              <span class="visually-hidden">Loading...</span>
                            </div>
                          </div>
                        )}
                      </td>
                      <td className>
                        {item?.totalFaming ? (
                          formatAmount(item.totalFaming) + " " + item.symbol
                        ) : (
                          <div className="d-flex">
                            <div class="spinner-border text-primary" role="status" style={{ height: "25px", width: "25px" }}>
                              <span class="visually-hidden">Loading...</span>
                            </div>
                          </div>
                        )}
                      </td>
                      <td className>
                        {item?.tvl ? (
                          formatAmount(item?.tvl) + "$"
                        ) : (
                          <div className="d-flex">
                            <div class="spinner-border text-primary" role="status" style={{ height: "25px", width: "25px" }}>
                              <span class="visually-hidden">Loading...</span>
                            </div>
                          </div>
                        )}
                      </td>
                    </tr>
                  );
                })
              : ""}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default FixedTokens;
