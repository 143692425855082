import { ethers } from "ethers";
export let provider;

export const setProvider = async (walletProvider, chains) => {
  if (provider) {
    provider = new ethers.providers.Web3Provider(walletProvider);
  } else {
    provider = new ethers.providers.JsonRpcProvider(chains[0].rpcUrl);
  }
};

function delay(delay) {
  return new Promise((res) => setTimeout(res, delay));
}

export function formatDate(timestemp) {
  let timems = timestemp * 1000;
  let day = new Date(timems).getDate();
  let month = new Date(timems).getMonth() + 1;
  let year = new Date(timems).getFullYear();
  return `${day.toString().padStart(2, "0")}/${month.toString().padStart(2, "0")}/${year.toString().padStart(4, "0")}`;
}

const getFullMonthName = (month) => {
  switch (month) {
    case 0:
      return "January";
    case 1:
      return "February";
    case 2:
      return "March";
    case 3:
      return "April";
    case 4:
      return "May";
    case 5:
      return "June";
    case 6:
      return "July";
    case 7:
      return "August";
    case 8:
      return "September";
    case 9:
      return "October";
    case 10:
      return "November";
    case 11:
      return "December";
    default:
      return "";
  }
};

export function formatAddress(text, size = 5) {
  if (text) {
    return text.slice(0, size) + "..." + text.slice(-size);
  } else {
    return "";
  }
}

export function dateDifference(timestemp) {
  const diff = Number(timestemp) + 60 * 60 * 24 - new Date().getTime() / 1000;
  const day = Math.floor(diff / (60 * 60 * 24));
  const hour = Math.floor((diff / (60 * 60)) % 24);
  const minute = Math.floor((diff / 60) % 60);
  return `${day >= 0 ? day.toString().padStart(2, "0") : "00"}D : ${hour >= 0 ? hour.toString().padStart(2, "0") : "00"}H : ${
    minute >= 0 ? minute.toString().padStart(2, "0") : "00"
  }M`;
}

function removeTrailingZeros(num) {
  let numStr = num.toString();
  if (numStr.indexOf(".") !== -1) {
    numStr = numStr.replace(/0+$/, ""); // Remove trailing zeros
    numStr = numStr.replace(/\.$/, ""); // Remove trailing dot if there is any
  }
  return numStr;
}

export function formatAmount(number) {
  let ethnum = parseFloat(number);
  let output = 0;

  if (ethnum == 0 || ethnum <= 0) {
    return 0.0;
  } else if (ethnum < 0.0000001) {
    output = parseFloat(ethnum).toFixed(11);
  } else if (ethnum >= 0.0000001 && ethnum < 0.000001) {
    output = parseFloat(ethnum).toFixed(10);
  } else if (ethnum >= 0.000001 && ethnum < 0.00001) {
    output = parseFloat(ethnum).toFixed(9);
  } else if (ethnum >= 0.00001 && ethnum < 0.0001) {
    output = parseFloat(ethnum).toFixed(8);
  } else if (ethnum >= 0.0001 && ethnum < 0.001) {
    output = parseFloat(ethnum).toFixed(7);
  } else if (ethnum >= 0.001 && ethnum < 0.01) {
    output = parseFloat(ethnum).toFixed(6);
  } else if (ethnum >= 0.01 && ethnum < 0.1) {
    output = parseFloat(ethnum).toFixed(5);
  } else if (ethnum >= 0.1 && ethnum < 1) {
    output = parseFloat(ethnum).toFixed(4);
  } else if (ethnum >= 1 && ethnum < 10) {
    output = parseFloat(ethnum).toFixed(3);
  } else if (ethnum >= 10 && ethnum < 100) {
    output = parseFloat(ethnum).toFixed(2);
  } else if (ethnum >= 100 && ethnum < 1000) {
    output = parseFloat(ethnum).toFixed(1);
  } else if (ethnum >= 1000) {
    output = parseFloat(ethnum).toFixed(0);
  }
  return removeTrailingZeros(output);
}
