// -- production ---
const chainsProd = [
  {
    chainId: 56,
    name: "BNB Smart Chain",
    currency: "BNB",
    explorerUrl: "https://bscscan.com/",
    rpcUrl: process.env.REACT_APP_HTTP_RPC,
  },
];

const metadataProd = {
  name: "Onmax Staking",
  description: "Onmax Staking",
  url: "https://app.onmaxyield.farm", // origin must match your domain & subdomain
  icons: ["https://onmaxyield.farm/favicon.png"],
};

// -- testing ---

const chainsTest = [
  {
    chainId: 97,
    name: "BNB Smart Chain Testnet",
    currency: "BNB",
    explorerUrl: "https://testnet.bscscan.com",
    rpcUrl: process.env.REACT_APP_HTTP_RPC,
  },
];

const metadataTest = {
  name: "Onmx Staking",
  description: "Onmx Staking",
  url: "https://yeild.xeer.store/", // origin must match your domain & subdomain
  icons: ["https://yeild.xeer.store/assets/img/token/onmx.png"],
};

export { chainsProd, chainsTest, metadataProd, metadataTest };
