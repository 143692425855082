import React from "react";

const Pagination = ({ currentPage, setCurrentPage, pages = 1 }) => {
  return (
    <>
      <div className="col-xl-12 mt-5">
        <div className="table-pagenation mb-3 d-flex justify-content-center">
          <nav>
            <ul className="pagination pagination-gutter pagination-primary no-bg">
              <li className="page-item page-indicator">
                <button type="button" className="page-link" onClick={() => setCurrentPage(1)} disabled={currentPage <= 1}>
                  <i className="fa-solid fa-angle-left" />
                </button>
              </li>
              {currentPage - 1 >= 1 ? (
                <li className="page-item ">
                  <button type="button" className="page-link" onClick={() => setCurrentPage(currentPage - 1)}>
                    {currentPage - 1}
                  </button>
                </li>
              ) : (
                ""
              )}
              <li className="page-item active">
                <button type="button" className="page-link">
                  {currentPage}
                </button>
              </li>
              {pages >= currentPage + 1 ? (
                <li className="page-item">
                  <button type="button" className="page-link" onClick={() => setCurrentPage(currentPage + 1)}>
                    {currentPage + 1}
                  </button>
                </li>
              ) : (
                ""
              )}
              <li className="page-item page-indicator me-0">
                <button type="button" className="page-link" disabled={currentPage >= pages} onClick={() => setCurrentPage(pages)}>
                  <i className="fa-solid fa-angle-right" />
                </button>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </>
  );
};

export default Pagination;
