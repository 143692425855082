import React, { useContext, useEffect, useState } from "react";
import { formatAddress, formatAmount } from "../../functions/common";
import { appContext } from "../../AppContext";
import { Tokens } from "../../config/config";

const HarvestInfoModal = ({ open, setOpen, info, handleOpenModal, getAllFarmFixed, getAllFarmFlexible }) => {
  const {
    getContractFixed,
    getContractFlexible,
    getContractCrossFixed,
    getContractCrossFlexible,
    toastSuccess,
    getTokenBalanceForAddress,
    toastError,
  } = useContext(appContext);
  const [spinner, setSpinner] = useState(false);
  const [hash, setHash] = useState("");

  const harvest = async () => {
    try {
      setSpinner(true);

      if (info.type === 0) {
        const contract = await getContractFixed();
        const contractBalFarm = await getTokenBalanceForAddress({ address: info?.token }, process.env.REACT_APP_CONTRACT_FIXED);
        const contractBalHarvest = await getTokenBalanceForAddress(Tokens[0], process.env.REACT_APP_CONTRACT_FIXED);
        // if (Number(info?.value) <= Number(contractBalFarm) && Number(info?.totalReward) > Number(contractBalHarvest)) {
        if (Number(info?.totalReward) <= Number(contractBalHarvest)) {
          const tx = await contract.harvest(info.id);

          const receipt = await tx.wait();
          if (receipt?.transactionHash) {
            setHash(receipt?.transactionHash);
            await handleOpenModal(info.id, info.type);
            await getAllFarmFixed();
            toastSuccess("Harvest successfully.");
          }
        } else {
          toastError("Something went wrong. Please try again later.", 4000);
        }

        setSpinner(false);
      } else if (info.type === 1) {
        const contract = await getContractFlexible();
        const contractBal = await getTokenBalanceForAddress({ address: info?.token }, process.env.REACT_APP_CONTRACT_FLEXIBALE);
        const contractBalHarvest = await getTokenBalanceForAddress(Tokens[0], process.env.REACT_APP_CONTRACT_FIXED);
        // if (Number(info?.value) <= Number(contractBal) && Number(info?.totalReward) > Number(contractBalHarvest)) {
        if (Number(info?.totalReward) <= Number(contractBalHarvest)) {
          const tx = await contract.harvest(info.id);

          const receipt = await tx.wait();
          if (receipt?.transactionHash) {
            setHash(receipt?.transactionHash);
            await handleOpenModal(info.id, info.type);
            await getAllFarmFlexible();
            toastSuccess("Harvest successfully.");
          }
        } else {
          toastError("Something went wrong. Please try again later.", 4000);
        }
        setSpinner(false);
      } else if (info.type === 2) {
        const contract = await getContractCrossFixed();
        const contractBal = await getTokenBalanceForAddress({ address: info?.token }, process.env.REACT_APP_CONTRACT_CROSS_FIXED);
        const contractBalHarvest = await getTokenBalanceForAddress(
          { address: info?.harvestToken },
          process.env.REACT_APP_CONTRACT_CROSS_FIXED
        );
        // if (Number(info?.value) <= Number(contractBal) && Number(info?.totalReward) > Number(contractBalHarvest)) {
        if (Number(info?.totalReward) <= Number(contractBalHarvest)) {
          const tx = await contract.harvest(info.id);
          const receipt = await tx.wait();
          if (receipt?.transactionHash) {
            setHash(receipt?.transactionHash);
            await handleOpenModal(info.id, info.type);
            await getAllFarmFixed();
            toastSuccess("Unfarm successfully.");
          }
        } else {
          toastError("Something went wrong. Please try again after some time.", 4000);
        }
        setSpinner(false);
      } else if (info.type === 3) {
        const contract = await getContractCrossFlexible();
        const contractBal = await getTokenBalanceForAddress({ address: info?.token }, process.env.REACT_APP_CONTRACT_CROSS_FLEXIBALE);
        const contractBalHarvest = await getTokenBalanceForAddress(
          { address: info?.harvestToken },
          process.env.REACT_APP_CONTRACT_CROSS_FLEXIBALE
        );

        // if (Number(info?.value) <= Number(contractBal) && Number(info?.totalReward) > Number(contractBalHarvest)) {
        if (Number(info?.totalReward) <= Number(contractBalHarvest)) {
          const tx = await contract.harvest(info.id);
          const receipt = await tx.wait();
          if (receipt?.transactionHash) {
            setHash(receipt?.transactionHash);
            await handleOpenModal(info.id, info.type);
            await getAllFarmFlexible();
            toastSuccess("Unfarm successfully.");
          }
        } else {
          toastError("Something went wrong. Please try again later.", 4000);
        }
        setSpinner(false);
      }
      setSpinner(false);
    } catch (err) {
      // console.log("error in harvest : ", err);
      setSpinner(false);
    }
  };
  const unfarm = async () => {
    try {
      setSpinner(true);
      if (info.type === 0) {
        const contract = await getContractFixed();
        const tx = await contract.unfarm(info.id);
        const receipt = await tx.wait();
        if (receipt?.transactionHash) {
          setHash(receipt?.transactionHash);
          await handleOpenModal(info.id, info.type);
          await getAllFarmFixed();
          toastSuccess("Unfarm successfully.");
        }
        setSpinner(false);
      } else if (info.type === 1) {
        const contract = await getContractFlexible();
        const tx = await contract.unfarm(info.id);
        const receipt = await tx.wait();
        if (receipt?.transactionHash) {
          setHash(receipt?.transactionHash);
          await handleOpenModal(info.id, info.type);
          await getAllFarmFlexible();
          toastSuccess("Unfarm successfully.");
        }
        setSpinner(false);
      } else if (info.type === 2) {
        const contract = await getContractCrossFixed();
        const tx = await contract.unfarm(info.id);
        const receipt = await tx.wait();
        if (receipt?.transactionHash) {
          setHash(receipt?.transactionHash);
          await handleOpenModal(info.id, info.type);
          await getAllFarmFixed();
          toastSuccess("Unfarm successfully.");
        }
        setSpinner(false);
      } else if (info.type === 3) {
        const contract = await getContractCrossFlexible();
        const tx = await contract.unfarm(info.id);
        const receipt = await tx.wait();
        if (receipt?.transactionHash) {
          setHash(receipt?.transactionHash);
          await handleOpenModal(info.id, info.type);
          await getAllFarmFlexible();
          toastSuccess("Unfarm successfully.");
        }
        setSpinner(false);
      }
      setSpinner(false);
    } catch (err) {
      // console.log("error in harvest : ", err);
      setSpinner(false);
    }
  };

  const closeModal = () => {
    setOpen(false);
    setHash("");
  };

  return (
    <div className={`modal fade ${open ? "show d-block" : ""}`} id="postModal" style={{ background: "#00000059" }}>
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content modal-md">
          <div className="modal-header">
            <h5 className="modal-title">Info</h5>
            <button type="button" className="btn-close" onClick={() => closeModal()}></button>
          </div>
          <div className="modal-body">
            <div className="card-body pt-4">
              <form>
                <div className="row g-3 mb-3">
                  <div className="col-12">
                    <h6> Reward Info</h6>
                    <div className="d-flex justify-content-between">
                      <p className="m-0">Days :</p>
                      <p className="m-0">{info?.day}</p>
                    </div>
                    <div className="d-flex justify-content-between">
                      <p className="m-0">Daily Percentage :</p>
                      <p className="m-0">{formatAmount(info?.dailyPer)}%</p>
                    </div>
                    <div className="d-flex justify-content-between">
                      <p className="m-0">Daily Reward :</p>
                      <p className="m-0">
                        {formatAmount(info?.dailyReward)} {info?.hsymbol}
                      </p>
                    </div>
                    <div className="d-flex justify-content-between">
                      <p className="m-0">Total Reward :</p>
                      <p className="m-0">
                        {formatAmount(info?.totalReward)} {info?.hsymbol}
                      </p>
                    </div>
                    <hr className="my-2" />
                    <div className="d-flex justify-content-between">
                      <p className="m-0">Harvest after :</p>
                      <p className="m-0">{info?.harvestAfter}</p>
                    </div>
                    <hr className="my-2" />
                    <div className="d-flex justify-content-between">
                      <p className="m-0">UnFarm after :</p>
                      <p className="m-0">{info?.unfarmAfter}</p>
                    </div>
                  </div>
                  {hash ? (
                    <p className="my-2 d-flex justify-content-center">
                      <a href={`${process.env.REACT_APP_EXPLORER_URL}tx/${hash}`} target="_blank">
                        {formatAddress(hash)}
                      </a>
                    </p>
                  ) : (
                    ""
                  )}
                  <div className="d-flex justify-content-center gap-3">
                    {spinner ? (
                      <div class="spinner-border text-primary" role="status" style={{ height: "25px", width: "25px" }}>
                        <span class="visually-hidden">Loading...</span>
                      </div>
                    ) : info?.status ? (
                      <>
                        <button
                          type="button"
                          class="btn btn-primary mt-3"
                          // disabled={info?.harvestBtnDisable}
                          onClick={harvest}
                        >
                          Harvest
                        </button>
                      </>
                    ) : (
                      <div>
                        <p className="text-success mb-0">Completed</p>
                      </div>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HarvestInfoModal;
