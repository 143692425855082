import React from "react";
import { formatAddress, formatAmount, formatDate } from "../../functions/common";
import { useNavigate } from "react-router-dom";
import CopyBtn from "../Coman/CopyBtn";
import Pagination from "../Coman/Pagination";

const CrossFlexibleTokens = ({ farms, loading, handleOpenModal, currentPage, setCurrentPage, pages }) => {
  return (
    <div className="tab-pane fade" id="crossflexible" role="tabpanel">
      <div className="table-responsive dataTabletrade">
        {loading ? (
          <div className="d-flex justify-content-center my-5">
            <div class="spinner-border text-primary" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : (
          <table id="example" className="table shadow-hover display" style={{ minWidth: "845px" }}>
            <thead>
              <tr role="row">
                <th
                  className="sorting"
                  tabIndex={0}
                  aria-controls="example3"
                  rowSpan={1}
                  colSpan={1}
                  aria-label=": activate to sort column ascending"
                  style={{ width: "38.5312px" }}
                >
                  No.
                </th>
                <th
                  className="sorting"
                  tabIndex={0}
                  aria-controls="example3"
                  rowSpan={1}
                  colSpan={1}
                  aria-label=": activate to sort column ascending"
                  style={{ width: "38.5312px" }}
                >
                  Logo
                </th>
                <th
                  className="sorting"
                  tabIndex={0}
                  aria-controls="example3"
                  rowSpan={1}
                  colSpan={1}
                  aria-label="Name: activate to sort column ascending"
                  style={{ width: "160.719px" }}
                >
                  Name
                </th>
                <th
                  className="sorting"
                  tabIndex={0}
                  aria-controls="example3"
                  rowSpan={1}
                  colSpan={1}
                  aria-label="Gender: activate to sort column ascending"
                  style={{ width: "92.5px" }}
                >
                  Token
                </th>
                <th
                  className="sorting"
                  tabIndex={0}
                  aria-controls="example3"
                  rowSpan={1}
                  colSpan={1}
                  aria-label="Gender: activate to sort column ascending"
                  style={{ width: "92.5px" }}
                >
                  Harvest Token
                </th>
                <th
                  className="sorting"
                  tabIndex={0}
                  aria-controls="example3"
                  rowSpan={1}
                  colSpan={1}
                  aria-label="Gender: activate to sort column ascending"
                  style={{ width: "92.5px" }}
                >
                  Lock (%)
                </th>
                <th
                  className="sorting"
                  tabIndex={0}
                  aria-controls="example3"
                  rowSpan={1}
                  colSpan={1}
                  aria-label="Gender: activate to sort column ascending"
                  style={{ width: "92.5px" }}
                >
                  Amount
                </th>
                <th
                  className="sorting"
                  tabIndex={0}
                  aria-controls="example3"
                  rowSpan={1}
                  colSpan={1}
                  aria-label="Gender: activate to sort column ascending"
                  style={{ width: "92.5px" }}
                >
                  Date
                </th>
              </tr>
            </thead>
            <tbody>
              {farms?.length
                ? farms?.map((item, ind) => {
                    return (
                      <tr role="row" className="odd c-pointer" key={item?.id}>
                        <td>{item.ind}</td>
                        <td>
                          <img className="rounded-circle" style={{ maxWidth: "40px" }} src={item?.token?.icon} alt="" />
                        </td>
                        <td>
                          {item?.token?.symbol}({item?.token?.name})
                        </td>
                        <td>
                          <div className="d-flex">
                            {formatAddress(item?.token?.address)} <CopyBtn text={item?.token?.address} />
                          </div>
                        </td>
                        <td>
                          <div className="d-flex">
                            {formatAddress(item?.harvestToken?.address)} <CopyBtn text={item?.harvestToken?.address} />
                          </div>
                        </td>
                        <td>{item?.lockPer}%</td>
                        <td>
                          {formatAmount(item?.value)} {item?.harvestToken?.symbol}
                        </td>
                        <td className>{formatDate(item?.datetime)}</td>
                      </tr>
                    );
                  })
                : ""}
            </tbody>
          </table>
        )}
        {!farms.length && !loading && (
          <div>
            <p className="text-center mt-2">No items found.</p>
          </div>
        )}
        <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} pages={pages} />
      </div>
    </div>
  );
};

export default CrossFlexibleTokens;
