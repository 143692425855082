import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Index from "./Components/Index/Index";
import Dashboard from "./Components/Dashboard/Dashboard";
import Farm from "./Components/Dashboard/Farm/Farm";
import FixedDetails from "./Components/Dashboard/FixedDetails/FixedDetails";
import FarmHistory from "./Components/History/FarmHistory";
import FlexibleDetails from "./Components/Dashboard/FlexibleDetails/FlexibleDetails";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Error404 from "./Components/Dashboard/Error404";
import TotalFarm from "./Components/Dashboard/TotalFarms/TotalFarm";
import Unfarm from "./Components/Unfarm/Unfarm";
import Harvest from "./Components/Harvest/Harvest";
import CrossFixedDetails from "./Components/Dashboard/CrossFixedDetails/CrossFixedDetails";
import CrossFlexibleDetails from "./Components/Dashboard/CrossFlexibleDetails/CrossFlexibleDetails";
import UnfarmHistory from "./Components/UnfarmHistory/UnfarmHistory";
import HarvestHistory from "./Components/HarvestHistory/HarvestHistory";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        {/* <Route path="/" element={<Index />} /> */}
        <Route path="/" element={<Dashboard />} />
        <Route path="/harvest" element={<Harvest />} />
        <Route path="/harvest-history" element={<HarvestHistory />} />
        <Route path="/farm" element={<Farm />} />
        {/* <Route path="/total-farm" element={<TotalFarm />} /> */}
        <Route path="/fixed" element={<FixedDetails />} />
        <Route path="/flexible" element={<FlexibleDetails />} />
        <Route path="/crossfixed" element={<CrossFixedDetails />} />
        <Route path="/crossflexible" element={<CrossFlexibleDetails />} />
        <Route path="/history" element={<FarmHistory />} />
        <Route path="/unfarm" element={<Unfarm />} />
        <Route path="/unfarm-history" element={<UnfarmHistory />} />
        <Route path="/error" element={<Error404 />} />
        <Route path="/*" element={<Error404 />} />
      </Routes>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        // theme="dark"
        pauseOnFocusLoss
        pauseOnHover
      />
    </BrowserRouter>
  );
}

export default App;
