import React from "react";
import DashboardMain from "./DashboardMain";
import Sidebar from "../Coman/Sidebar";

export default function Dashboard() {
  return (
    <>
      <div id="main-wrapper" className="show">
        <Sidebar />
        <DashboardMain />
      </div>
    </>
  );
}
