import React, { useContext, useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { appContext } from "../../AppContext";
import { provider } from "../../functions/common";
import { formatAddress } from "../../functions/common";
import copy from "copy-to-clipboard";

export default function Sidebar() {
  const { connectWallet, isConnected, address, disconnect, toastSuccess, sidebarToggle, handleToggleSidebarNav } = useContext(appContext);

  const copyAddress = () => {
    copy(address);
    toastSuccess("Copied!", 2000);
  };

  return (
    <>
      <div className="nav-header">
        <a href="/" className="brand-logo">
          {/* <img src="assets/images/onmax-logo.png" className="brand-title" alt="" /> */}
          <img src="assets/images/onmax-logo-light.png" className="brand-title" alt="" />

          {/* <img src="assets/images/logo/logo.png" className="logo-abbr" alt="" /> */}
          <img src="assets/images/onmax.png" className="logo-color" alt="" />
          {/* <img src="assets/images/logo/logo-text-color.png" className="brand-title color-title" alt="" /> */}
        </a>
        <div className="nav-control">
          <div className="hamburger">
            <span className="line" />
            <span className="line" />
            <span className="line" />
            <svg width={26} height={26} viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x={22} y={11} width={4} height={4} rx={2} fill="#2A353A" />
              <rect x={11} width={4} height={4} rx={2} fill="#2A353A" />
              <rect x={22} width={4} height={4} rx={2} fill="#2A353A" />
              <rect x={11} y={11} width={4} height={4} rx={2} fill="#2A353A" />
              <rect x={11} y={22} width={4} height={4} rx={2} fill="#2A353A" />
              <rect width={4} height={4} rx={2} fill="#2A353A" />
              <rect y={11} width={4} height={4} rx={2} fill="#2A353A" />
              <rect x={22} y={22} width={4} height={4} rx={2} fill="#2A353A" />
              <rect y={22} width={4} height={4} rx={2} fill="#2A353A" />
            </svg>
          </div>
        </div>
      </div>
      <div className="header">
        <div className="header-content">
          <nav className="navbar navbar-expand">
            <div className="collapse navbar-collapse justify-content-between">
              <div className="header-left">
                <div className="dashboard_bar"> </div>
              </div>
              <div className="navbar-nav header-right">
                <div className="nav-item d-flex align-items-center">
                  {isConnected ? (
                    <>
                      <button type="button" class="btn btn-rounded btn-primary mt-1 mx-1" onClick={() => copyAddress()}>
                        {formatAddress(address)}
                      </button>
                      <button type="button" class="btn btn-rounded btn-primary mt-1 mx-1 p-2 px-3" onClick={disconnect}>
                        <i class="material-icons mx-0">logout</i>
                      </button>
                    </>
                  ) : (
                    <button type="button" class="btn btn-rounded btn-primary mt-1" onClick={connectWallet}>
                      Connect Wallet
                    </button>
                  )}
                </div>
              </div>
            </div>
          </nav>
        </div>
      </div>
      <div className="deznav">
        <div className="deznav-scroll">
          <ul className="metismenu">
            <li>
              <Link to="/">
                <img src="/assets/images/dashboard.svg" className="mx-2" style={{ height: "18px" }} />
                <span className="nav-text">Dashboard</span>
              </Link>
            </li>
            {/* <li>
              <Link to="/total-farm" >
                <i className="material-icons">currency_bitcoin</i>
                <span className="nav-text">Total Farming</span>
              </Link>
            </li> */}
            <li>
              <a className="has-arrow" onClick={() => handleToggleSidebarNav("farm")} aria-expanded={sidebarToggle.farm ? "true" : "false"}>
                <img src="/assets/images/farm.svg" className="mx-2" style={{ height: "18px" }} />
                <span className="nav-text" style={{rotate: "30deg"}}>Farm</span>
              </a>
              <ul className={sidebarToggle.farm ? "d-block" : "d-none"}>
                <li>
                  <Link to="/farm">Start Farming</Link>
                </li>

                <li>
                  <Link to="/history">History</Link>
                </li>
              </ul>
            </li>
            <li>
              <a className="has-arrow" onClick={() => handleToggleSidebarNav("unfarm")} aria-expanded={sidebarToggle.unfarm ? "true" : "false"}>
                <img src="/assets/images/unfarm.svg" className="mx-2" style={{ height: "18px" }} />
                <span className="nav-text">Unfarm</span>
              </a>
              <ul className={sidebarToggle.unfarm ? "d-block" : "d-none"}>
                <li>
                  <Link to="/unfarm">Start Unfarm</Link>
                </li>

                <li>
                  <Link to="/unfarm-history">Unfarm History</Link>
                </li>
              </ul>
            </li>
            <li>
              <a className="has-arrow" onClick={() => handleToggleSidebarNav("harvest")} aria-expanded={sidebarToggle.harvest ? "true" : "false"}>
                <img src="/assets/images/harvest.svg" className="mx-2" style={{ height: "18px" }} />
                <span className="nav-text">Harvest</span>
              </a>
              <ul className={sidebarToggle.harvest ? "d-block" : "d-none"}>
                <li>
                  <Link to="/harvest">Start Harvest</Link>
                </li>

                <li>
                  <Link to="/harvest-history">Harvest History</Link>
                </li>
              </ul>
            </li>
            <li>
              <a href={`${process.env.REACT_APP_EXPLORER_URL}address/${process.env.REACT_APP_CONTRACT_FIXED}`} target="_blank">
                <img src="/assets/images/fixed_contract.svg" className="mx-2" style={{ height: "18px" }} />
                <span className="nav-text">Fixed Contract</span>
              </a>
            </li>
            <li>
              <a href={`${process.env.REACT_APP_EXPLORER_URL}address/${process.env.REACT_APP_CONTRACT_FLEXIBALE}`} target="_blank">
                <img src="/assets/images/fixed_contract.svg" className="mx-2" style={{ height: "18px" }} />
                <span className="nav-text">Flexible Contract</span>
              </a>
            </li>
            <li>
              <a href={`${process.env.REACT_APP_EXPLORER_URL}address/${process.env.REACT_APP_CONTRACT_CROSS_FIXED}`} target="_blank">
                <img src="/assets/images/fixed_contract.svg" className="mx-2" style={{ height: "18px" }} />
                <span className="nav-text">Hybrid Heal Contract</span>
              </a>
            </li>
            <li>
              <a href={`${process.env.REACT_APP_EXPLORER_URL}address/${process.env.REACT_APP_CONTRACT_CROSS_FLEXIBALE}`} target="_blank">
                <img src="/assets/images/fixed_contract.svg" className="mx-2" style={{ height: "18px" }} />
                <span className="nav-text">Hybrid Relax Contract</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}
