import React, { useState, useRef, useEffect } from "react";

const DropdownSelectBox = ({ tokens, selected, setSelected, disabled }) => {
  const [isOpen, setIsOpen] = useState(false); // Manage dropdown visibility state

  const dropdownRef = useRef(null); // Ref to track the dropdown element

  const handleSelect = (option) => {
    setSelected(option);
    setIsOpen(false); // Close dropdown after selection
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen); // Toggle dropdown open/close
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false); // Close dropdown if clicked outside
    }
  };

  useEffect(() => {
    // Add event listener to handle clicks outside
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Clean up the event listener when the component is unmounted
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="w-100">
      {disabled ? (
        <div className="dropdown-box w-100 opacity-50">
          <div className="form-control default-select dashboard-select-2 h-auto wide" style={{ cursor: "pointer" }}>
            <>
              <span className="mx-2">Select</span>
            </>
          </div>
          {false && <div className="dropdown-content border"></div>}
        </div>
      ) : (
        <div className="dropdown-box w-100" ref={dropdownRef}>
          <div
            className="form-control default-select dashboard-select-2 h-auto wide"
            onClick={toggleDropdown}
            style={{ cursor: "pointer" }}
          >
            {selected ? (
              <>
                <img
                  src={selected.icon}
                  alt={selected.symbol}
                  className="dropdown-image"
                  style={{ borderRadius: "50%", height: "22px", width: "22px" }}
                />
                <span className="mx-2">
                  {selected.symbol} ({selected.name}) ({selected.apy}% Apy)
                </span>
              </>
            ) : (
              <>
                <span className="mx-2">Select</span>
              </>
            )}
          </div>
          {isOpen && (
            <div className="dropdown-content border">
              {tokens?.length
                ? tokens.map((option) => (
                    <div key={option.address} className="dropdown-item" onClick={() => handleSelect(option)} style={{ cursor: "pointer" }}>
                      <img src={option.icon} alt={option.symbol} style={{ borderRadius: "50%", height: "22px", width: "22px" }} />
                      <span className="mx-2">
                        {option.symbol} ({option.name}) ({option.apy}% Apy)
                      </span>
                    </div>
                  ))
                : ""}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default DropdownSelectBox;
